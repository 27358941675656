import React from 'react'
import { Link } from 'gatsby'

import RightChevron from '../SVG/RightChevron'

const CTA = ({ offset }) => {
	return (
		<div className={`bg-grey-lightest pb-24 ${offset ? '-mt-84 pt-84 lg:-mt-64 lg:pt-64' : 'pt-24'} `}>
			<div className="wrapper">
				<div className="lg:w-5/12">
					<h3 className="font-bold text-2xl leading-snug mb-12 lg:mb-6">
						Don’t see what you’re after?
					</h3>
					<div className="rte mb-8 font-semibold">
						Is there another specialist technology you require? We are constantly upskilling our team, so most likely we can work with your technology requirements.
					</div>

					<Link
						className="inline-block button button--white bg-transparent py-5 mb-0 font-semibold border-blue border"
						to="/contact"
					>
						<div className="flex justify-between items-center">
							Enquire Now
							<div className="ml-8">
								<RightChevron />
							</div>
						</div>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default CTA
