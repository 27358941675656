import React from 'react'
import { Link } from 'gatsby'

import Logomark from '../SVG/Logomark'
import RightChevron from '../SVG/RightChevron'

const Hero = ({ lead, body, list, listHeading }) => {
  return (
    <div className="flex flex-wrap lg:mb-20 lg:-ml-12">
      <div className="w-full lg:w-6/12 lg:pl-12 pb-12 lg:pb-0 font-medium lg:pt-8">
        {lead && (
          <h3 className="text-xl lg:text-3xl leading-tight mb-6">
            {lead}
          </h3>
        )}
        {body.html && (
          <div
            className="rte mb-8"
            dangerouslySetInnerHTML={{ __html: body.html }}
          />
        )}
        <Link
          className="button button--blue py-5  px-6 border inline-block"
          to="/contact"
        >
          <div className="button__inner font-semibold">
            <span className="inline-block">Enquire About This Service</span>
            <RightChevron className="ml-8" />
          </div>
        </Link>
      </div>
      <div className="hidden lg:block lg:w-1/12 lg:pl-12 lg:pt-8"></div>
      <div className="lg:w-5/12 lg:pl-12">
        <div className="sticky top-0 left-0 pt-8 mb-8 lg:-mb-5">
          {listHeading && <h3 className="text-xl lg:text-2xl font-bold leading-tight mb-8">{listHeading}</h3>}
          {list && (<ul className="font-semibold lg:text-lg">
            {list
              .filter(item => item.list_item !== null)
              .map((item, index) => {
                return (
                  <li key={index} className="pb-5 flex items-start"><Logomark className="w-4 lg:w-5 mr-4 lg:mr-6 flex-shrink-0" />{item.list_item}</li>
                )
              })}
          </ul>)}
        </div>
      </div>
    </div>
  )
}

export default Hero
