import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import RightChevron from '../SVG/RightChevron'

const featureCard = ({ data, mobileFeature, position, inset }) => {
  return (
    <div className="-mx-5 lg:mx-0 relative overflow-visible mb-20">
      <div className="hidden lg:block">
        {data.featured_case_study.document.data.hero.url && (
          <Img
            className="overflow-hidden absolute bottom-0 left-0"
            objectPosition="50% 50%"
            fluid={
              data.featured_case_study.document.data.hero.localFile
                .childImageSharp.fluid
            }
            alt={data.featured_case_study.document.data.hero.alt}
          />
        )}
      </div>
      <div className="block lg:hidden">
        {mobileFeature.featured_case_study.document.data.hero && (
          <Link to={'/case-studies/' + data.featured_case_study.uid}>
            <Img
              fluid={
                mobileFeature.featured_case_study.document.data.hero.localFile
                  .childImageSharp.fluid
              }
              alt={mobileFeature.featured_case_study.document.data.hero.alt}
            />
          </Link>
        )}
      </div>
      <div
        className={`lg:mr-12 lg:pl-12 lg:absolute right-0 w-full${
          position === 'top' ? ' top-0' : ''
        }${position === 'bottom' ? ' bottom-0' : ''}`}
      >
        <div className="lg:flex">
          <div className="hidden lg:block w-8/12 flex-none" />
          <div
            className={`bg-blue text-white p-8${
              position === 'top' ? ' lg:-mt-8' : ''
            }${position === 'bottom' ? ' lg:-mb-8' : ''}${
              inset === true ? ' mx-5 lg:mx-0 -mt-42 z-20 relative' : ''
            }`}
          >
            <span className="block text-sm font-bold mb-4">
              Featured Case Study
            </span>
            {data.featured_case_study.document.data.heading && (
              <Link
                className="button-group"
                to={'/case-studies/' + data.featured_case_study.uid}
              >
                <h3 className="inline-block text-4xl font-medium leading-tight mb-10">
                  {data.featured_case_study.document.data.heading}
                </h3>
              </Link>
            )}
            {data.featured_case_study_text.html && (
              <div
                className="mb-10 font-semibold"
                dangerouslySetInnerHTML={{
                  __html: data.featured_case_study_text.html,
                }}
              />
            )}
            {data.featured_case_study.uid && (
              <Link
                className="inline-block button button--blue border"
                to={'/case-studies/' + data.featured_case_study.uid}
              >
                <div className="button__inner">
                  Learn More
                  <RightChevron className="ml-8" />
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default featureCard
