import React from 'react'
import { Link } from 'gatsby'

import RightChevron from '../SVG/RightChevron'

const Intro = ({ data }) => {
  let services = data.services.filter(item => item.service_item.document !== null)
  if (services.length === 0) return null
  return (
    <div className="mb-16 lg:mb-12">
      <div className="flex flex-wrap sm:justify-between -wrapper lg:mx-0">
        {services.map((item, index) => {
          let service = item.service_item.document
          return (
            <div
              key={index}
              className="border-b lg:border-b-0 w-full lg:flex-1 lg:text-center mb-5 pb-4 lg:mb-0 lg:pb-0 px-5 md:px-8 lg:px-0"
            >
              <Link
                to={`${service.data.category.uid}/${service.uid}/`}
                className="w-full flex items-center justify-between lg:block lg:h-full button p-0 service-item">
                <div className="flex items-center lg:block focus:outline-none lg:pb-3 lg:h-full service-item__inner lg:font-bold">
                  {service.data.icon.url && (
                    <img
                      className="h-11 lg:h-16 lg:mx-auto lg:mb-2"
                      src={service.data.icon.url}
                      alt={service.data.icon.alt}
                    />
                  )}
                  {service.data.title && (
                    <h4 className="px-5 lg:px-0 text-base inline lg:pb-3 lg:border-b-0 font-bold lg:font-normal">
                      {service.data.title}
                    </h4>
                  )}
                </div>
                <div className="focus:outline-none lg:hidden">
                  <div className="button__inner">
                    <span className="hidden sm:inline-block">Learn More</span>
                    <RightChevron className="ml-8" />
                  </div>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Intro
