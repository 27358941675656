import React from 'react'

const Content = ({ content }) => {
	const result = content.filter(item => item.content_heading !== null && item.content_body.html !== "")

	if (result.length === 0) return null
	return (
		<div className="lg:text-lg">
			{result.map((item, index) => {
				return (
					<div key={index} className="lg:flex lg:-ml-12 pb-16 lg:pb-24">
						<div className="w-full lg:w-6/12 lg:pl-12">
							<div className="sticky top-0 left-0 pt-8">
								<h3 className="lg:w-1/2 block heading-xl mb-8 lg:mb-0">{item.content_heading}</h3>
							</div>
						</div>
						<div className="hidden lg:block lg:w-1/12 pl-12 pt-8"></div>
						<div className="lg:w-5/12 lg:pl-12 lg:pt-8">
							<div className="rte font-medium" dangerouslySetInnerHTML={{ __html: item.content_body.html }} />
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default Content
